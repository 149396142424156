

@keyframes opacity {
    0% {
        opacity: 1;
        
    }

    50% {
        opacity: 0;
        
    }

    100% {
        opacity: 1;
            
    }
}
@keyframes beat {
    0% {
      transform: scale(1);
    }
  
    50% {
      transform: scale(1.2);
    }
  
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes Colors {
    0%, 100% {
      fill:var(--secondary) !important;
    }
  
    50% {
      fill:var(--primary) !important;
    }
}