.Services_Card{

  .card {
    direction: ltr;
    position: relative;

    width: 190px;
    height: 254px;
    background-color: var(--bg2);
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 12px;
    border-radius: 8px;
    cursor: pointer;
    margin: 30px 5vw;
  }
  @include devices(mobile){
    .card{
      margin: 30px 0 ;
    }
  }
  
  .card::before {
    content: '';
    position: absolute;
    inset: 0;
    left: -5px;
    margin: auto;
    width: 200px;
    height: 264px;
    border-radius: 10px;
    background: linear-gradient(-45deg, var(--secondary) 0%, var(--secondary) 100% );
    z-index: -10;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .card::after {
    content: "";
    z-index: -1;
    position: absolute;
    inset: 0;
    background: linear-gradient(-45deg, var(--secondary) 0%, var(--secondary) 100% );
    transform: translate3d(0, 0, 0) scale(0.95);
    filter: blur(20px);
  }
  
  
  .heading {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 700;
  }
  
  .card p:not(.heading) {
    font-size: 14px;
  }
  
  .card p:last-child {
    color: #e81cff;
    font-weight: 600;
  }
  
  .card:hover::after {
    filter: blur(30px);
  }
  
  .card:hover::before {
    transform: rotate(-90deg) scaleX(1.34) scaleY(0.77);
  }
  
  
  .button {
    position: relative;
    padding: 10px 22px;
    border-radius: 6px;
    border: none;
    color: #fff;
    cursor: pointer;
    background-color: var(--secondary);
    transition: all 0.2s ease;
    width: 100%;
  }
  
  
  .button:active {
    transform: scale(0.96);
  }
  
  .button:before,
  .button:after {
    position: absolute;
    content: "";
    width: 150%;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
    z-index: -1000;
    background-repeat: no-repeat;
  }
  
  .button:hover:before {
    top: -70%;
    background-image: radial-gradient(circle, var(--secondary) 20%, transparent 20%),
      radial-gradient(circle, transparent 20%, var(--secondary) 20%, transparent 30%),
      radial-gradient(circle, var(--secondary) 20%, transparent 20%),
      radial-gradient(circle, var(--secondary) 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, var(--secondary) 15%, transparent 20%),
      radial-gradient(circle, var(--secondary) 20%, transparent 20%),
      radial-gradient(circle, var(--secondary) 20%, transparent 20%),
      radial-gradient(circle, var(--secondary) 20%, transparent 20%),
      radial-gradient(circle, var(--secondary) 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
      10% 10%, 18% 18%;
    background-position: 50% 120%;
    animation: greentopBubbles 0.6s ease;
    
  }
  
  @keyframes greentopBubbles {
    0% {
      background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
        40% 90%, 55% 90%, 70% 90%;
    }
  
    50% {
      background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
        50% 50%, 65% 20%, 90% 30%;
    }
  
    100% {
      background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
        50% 40%, 65% 10%, 90% 20%;
      background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
  }
  
  .button:hover::after {
    bottom: -70%;
    background-image: radial-gradient(circle, var(--secondary) 20%, transparent 20%),
      radial-gradient(circle, var(--secondary) 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, var(--secondary) 15%, transparent 20%),
      radial-gradient(circle, var(--secondary) 20%, transparent 20%),
      radial-gradient(circle, var(--secondary) 20%, transparent 20%),
      radial-gradient(circle, var(--secondary) 20%, transparent 20%),
      radial-gradient(circle, var(--secondary) 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%, 18% 18%;
    background-position: 50% 0%;
    animation: greenbottomBubbles 0.6s ease;
  }
  
  @keyframes greenbottomBubbles {
    0% {
      background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
        70% -10%, 70% 0%;
    }
  
    50% {
      background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
        105% 0%;
    }
  
    100% {
      background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
        110% 10%;
      background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
  }
}
.Services_Card{
  .card{
    svg{
      font-size: 70px;
      margin: 0 auto;
      transform: translateY(10px);
      -webkit-transform: translateY(10px);
      -moz-transform: translateY(10px);
      -ms-transform: translateY(10px);
      -o-transform: translateY(10px);
}
    h1{
      font-size: 25px;
      font-weight: bold;
      margin-top: 30px;
    }
  }
button{

}

}



.change-logo-color{
  fill: red !important;
}