.ContactUs{
    padding-left: 3vw;
    .ContactUs-container{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 40px;
         h2{
            color: var(--text);
            font-size: 4.6em;
            font-weight: bold;
            margin-bottom: 100px;
            border-bottom: 2.5px solid var(--secondary);
            padding-bottom: 10px;
         }
         .ContactUs-Mid-Section{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column-reverse;
            width: 100%;
           
            .ContactUs-Mid-LeftSection{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 340px;
                width: 50%;
                border-right: 3px solid var(--secondary);
    
                .ContactUs-Information{
                    display: flex;
                    align-items: center;
                    width: 50%;
                    background-color: var(--bg);
                    height: 70px;
                    padding: 0px 20px;
                    margin: 10px 0px 10px 0px ;
                    border-radius: 8px;
                    
                    .ContactUs-Information-icon{
                        font-size: 40px;
                        background-color: var(--text);
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: var(--secondary);
                    }
                    .ContactUs-Information-inform{
                        color: var(--secondary);
                        font-size: 13px;
                        padding-left: 10px;
    
                    }
    
                }
            }
            .ContactUs-Mid-RightSection{
                width: 50%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-direction: column;
                height: 100px;
                .ContactUs-Mid-RightSection-Container{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 50%;
                    margin-right: 85px;
                }
                label{
                   
                    width: 50%;
                    align-self: start;
                    color: var(--secondary);
                    font-size: 15px;
                    height: 10px;
                }
                input ,textarea{
                    align-self: start;
                    width: 100%;
                    padding: 15px;
                    border: none;
                    outline: none;
                    // height: 25px;
                    border-radius: 8px;
                    background-color: var(--bg);
                }
                textarea{
                    font-size: 1.5em;
                    height: 120px !important;
                    resize: none;
                }
                .message{
                    height: 200px;
                }
                button{
                    align-self: center;
                    background-color: var(--secondary);
                    width: 100%;
    
                    height: 43px;
                    border-radius: 8px;
                    margin-top: 17px;
                    color: var(--text);
                    font-size: 18px;
                    font-weight: bold;
                    
                    cursor: pointer;
                }
                button:hover{
                    transform: scale(1.05) ;
                    transition: 0.4s ease-in-out;
                    -webkit-transform: scale(1.05) ;
                    -moz-transform: scale(1.05) ;
                    -ms-transform: scale(1.05) ;
                    -o-transform: scale(1.05) ;
}
            }
         }
    
    }
    
    .ContactUs-container2{
        .container{
            width: 100%;
            min-height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 30px 8%;
        }
        .container .row{
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 1100px;
        }
        .row section.col{
            display: flex;
            flex-direction: column;
            
        }
        .row section.left{
            flex-basis: 35%;
            // min-width: 320px;
            margin-right: 60px;
        }
        .row section.right{
            flex-basis: 60%;
        }
        section.left .contactTitle h2{
            position: relative;
            font-size: 28px;
            color: var(--text);
            display: inline-block;
            margin-bottom: 25px;
            
        }
        section.left .contactTitle h2::before{
            content: '';
            position: absolute;
            width: 50%;
            height: 1px;
            background-color: var(--secondary);
            top: 120%;
            left: 0;
            right: 0;
        }
        section.left .contactTitle h2::after{
            content: '';
            position: absolute;
            width: 25%;
            height: 3px;
            background-color: var(--secondary);
            top: calc(120% - 1px);
            left: 0;
        }
        section.left .contactTitle p{
            font-size: 17px;
            color: var(--text);
            letter-spacing: 1px;
            line-height: 1.2;
            padding-bottom: 22px;
            max-width: 50vw; 
            word-break: normal;
        }

        section.left .contactInfo{
            margin-bottom: 16px;
            // background: red;
            max-width: 90%;
        }
        .contactInfo .iconGroup{
            display: flex;
            align-items: center;
            margin: 25px 0px;
        }
        .iconGroup .icon{
            width: 45px;
            height: 45px;
            border: 2px solid var(--secondary);
            border-radius: 50%;
            margin-right: 20px;
            position: relative;
        }
        .iconGroup .icon i{
            font-size: 20px;
            color: var(--text);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .iconGroup .details span{
            display: block;
            color: var(--secondary);
            // max-width: 25vw;
            font-size: 18px;
        }
        @include devices(sh7ata){
            .iconGroup .details span{
                // max-width: 25vw;
                // background: red;
            }
        }
        .iconGroup .details span:nth-child(1){
            text-transform: uppercase;
            color: var(--text);
        }
        section.left .socialMedia{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            margin: 22px 0px 20px;
            // max-width: 25vw;
        }
        .socialMedia a{
            width: 35px;
            height: 35px;
            text-decoration: none;
            text-align: center;
            margin-right: 15px;
            border-radius: 5px;
            background-color: var(--secondary);
            transition: 0.4s;
        }
        .socialMedia a i{
            color: var(--text);
            font-size: 18px;
            line-height: 35px;
            border: 1px solid transparent;
            transition-delay: 0.4s;
        }
        .socialMedia a:hover{
            transform: translateY(-5px);
            background-color: var(--bg);
            color: var(--secondary);
            border: 1px solid var(--secondary);
        }
        .socialMedia a:hover i{
            color: var(--secondary);
        }
        
        /* Code for the right section (column) */
        
        .row section.right .messageForm{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-top: 30px;
        }
        .row section.right .inputGroup{
            margin: 18px 0px;
            position: relative;
        }
        .messageForm .halfWidth{
            flex-basis: 48%;
        }
        .messageForm .fullWidth{
            flex-basis: 100%;
        }
        .messageForm input, .messageForm textarea{
            width: 100%;
            font-size: 18px;
            padding: 7px !important;
            background-color: var(--bg);
            color: var(--text);
            border: none;
            border-radius: 7px;
            border-bottom: 1px solid var(--secondary);
            outline: none;
        }
        .messageForm textarea{
            resize: none;
            height: 220px;
            display: block;
            padding: 10px;
        }
        textarea::-webkit-scrollbar{
            width: 5px;
        }
        textarea::-webkit-scrollbar-track{
            background-color: var(--bg);
            border-radius: 15px;
        }
        textarea::-webkit-scrollbar-thumb{
            background-color: var(--primary);
            border-radius: 15px;
        }
        .inputGroup label{
            position: absolute;
            left: 5px;
            bottom: 4px;
            color: var(--text);
            font-size: 14px;
            transition: 0.4s;
            opacity: 0.3;
            pointer-events: none;
        }
        .inputGroup:nth-child(4) label{
            top: 2px;
        }
        .inputGroup input:focus ~ label, .inputGroup textarea:focus ~ label,
        .inputGroup input:valid ~ label, .inputGroup textarea:valid ~ label
        {
            transform: translateY(-30px);
            font-size: 16px;
            color: var(--secondary);
            opacity: 1;
        }
        .inputGroup button{
            padding: 8px 16px;
            font-size: 18px;
            background-color: var(--bg);
            color: var(--text);
            border: 1px solid transparent;
            border-radius: 3px;
            outline: none;
            cursor: pointer;
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
            transition: 0.4s;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
}
        .inputGroup button:hover{
            background-color: var(--bg);
            color: var(--secondary);
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
            border: 1px solid var(--secondary);
        }
        @media(max-width: 1100px){
            .messageForm .halfWidth{
                flex-basis: 100%;
            }
        }
        @media(max-width: 900px){
            .container .row{
                flex-wrap: wrap;
            }
            .row section.left, .row section.right{
                flex-basis: 100%;
                margin: 0px;
            }
        }
    }
    
    
    @include devices(mobile){
    
       
    }
}
main{
    .First_Sec{
        width: 60vw !important;
        .contactTitle{
            max-width: 50vw;
            margin-right: 0vw;
            
        }
    }
}