.loading-page{

  height: 100vh;
  width: 100vw;
  background: var(--bg);
  display: flex;
  justify-content: center;
  align-items: center;

  .con{

  }
}