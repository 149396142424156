.circle-service{
  direction: ltr;
   position: relative;
  nav{
      // position: absolute;
      top: 20px;
      right: 0;
      width: 80px;
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: grab;
    }
    nav .nav-content{
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(-45deg);
    }
    .nav-content .toggle-btn,
    .nav-content span a{
      height: 60px;
      width: 60px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0 0 20px rgba(0,0,0,0.2);
    }
    .nav-content .toggle-btn{
      font-size: 35px;
      color: #0e2431;
      z-index: 100;
      cursor: pointer;
      transform: rotate(-225deg);
      transition: all 0.6s ease;
    }
    nav.open .toggle-btn{
      transform: rotate(0deg);
    }
    .nav-content span{
      position: absolute;
      transition: all 0.6s ease;
      opacity: 0;
    }
    nav.open .nav-content span{
      transform: rotate(calc(var(--i) * (360deg/8))) translateY(120px);
      opacity: 1;
    }
    .nav-content span a{
      text-decoration: none;
      transform: rotate(45deg);
    }
    .nav-content span a i{
      font-size: 24px;
      color: #0e2431;
      transform: rotate(calc(var(--i) * (360deg/ -8)));
      opacity: 0.8;
      transition: 0.2s;
    }
    .nav-content span a:hover i{
      opacity: 1;
    }
    
    
    
}

.item-circle-service{
color:white ;
font-size: 5em;

}


@include devices(mobile){

.item-circle-service{
  font-size: 3em;
  
}
}

.close-circle-service{
width: 50px; 
height: 50px;
border-radius: 50%;
border: 1px solid var(--secondary);
display: flex;
justify-content: center;
align-items: center;

}

.circle-service{
all: unset !important;


}
.Services{
svg{
  width: 60px;
  height:60px;
  filter: invert(48%) sepia(79%) saturate(0%) hue-rotate(286deg) brightness(217%) contrast(100%);
}

}



@include devices(mobile){

.Services{
  svg{
    width: 40px;
    height:40px;
  }
 
}
}