
.app-servic-page{
    position: absolute;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    z-index: 999;
    padding-inline: 10px;
    .single-service{
    
        max-width: 1200px;
        width: 90vw;
        margin: 100px auto;
        @include Shadow ;
        border: 1px solid var(--secondary);
        min-height: 400px;
        display: flex;
        justify-content: center;


      

      .details{

        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 30px 0;
      }
      .details .big-img{
        max-width: 400px;
        max-height: 400px;
        min-width: 300px;
        overflow: hidden;
        margin: 25px;
      }
      .big-img img, video{
        width: 100%;
        height: 100%;
        max-height: 400px;
        display: block;
        object-fit: cover;
      }
      
      .details .box{
        max-width: 500px;
        min-width: 290px;
        margin: 25px;
      }
      .box .row{
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
      }
      .box .row h2{
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 3.2em;
        font-weight: bold;
        color: var(--secondary);
      }
   
      .box .colors button{
        width: 30px;
        height: 30px;
        border: 1px solid #333;
        outline: none;
        margin-right: 5px;
        cursor: pointer;
      }
      .box .description{
        font-size: 2em;
        color: var(--text);

        line-height: 1.7;
        margin: 15px 0;
      }
      .thumb{
        width: 100%;
        min-height: 100px;
        display: flex;
        cursor: pointer;
        flex-wrap: wrap;
        margin: 10px 0;
      }
      .thumb img{
        width: 90px;
        height: 100px;
        
        display: block;
        object-fit: cover;
        border: 1px solid #ddd;
        margin-right: 25px;
        opacity: 0.7;
        margin: 10px 25px 10px 0;
        border-radius: 5px;
      }
      .thumb img.active{
        opacity: 1;
        border: 1px solid lightseagreen;
      }
      .box .cart{
        background: #333;
        color: white;
        outline: none;
        border: none;
        cursor: pointer;
        padding: 10px 25px;
        margin-top: 15px;
      }
      
      @media (max-width: 500px){
        .thumb{
          min-height: 50px;
        }
        .thumb img{
          width: 70px;
        }
      }
}
}


.loader-company{
    text-align: center;
    position: relative;
     span {
        position: absolute;
        color: #fff;
        top: 40px;
        transform: translate(-50%, -50%);
        font-size: 4vw;
        letter-spacing: 5px;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
}
      
       span:nth-child(1) {
        color: transparent;
        -webkit-text-stroke: 0.3px var(--secondary);
      }
      
       span:nth-child(2) {
        color: var(--secondary);
        -webkit-text-stroke: 1px var(--secondary);
        animation: uiverse723 3s ease-in-out infinite;
      }
      
      @keyframes uiverse723 {
        0%, 100% {
          clip-path: polygon(0% 45%, 15% 44%, 32% 50%, 
           54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
        }
      
        50% {
          clip-path: polygon(0% 60%, 16% 65%, 34% 66%, 
           51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
        }
      }
}