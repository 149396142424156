
@tailwind base;
@tailwind components;
@tailwind utilities;
@mixin Flex($jc) {
    display: flex;
    justify-content: $jc;
    align-items: center !important;
}
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;

}

@mixin bc($B,$C) {
  background: var($B) !important;
  color:var($C) !important;
}
@mixin Shadow {
  box-shadow: 2px 2px 5px 0 var(--secondary);

}
@mixin Hover {
&:hover{
  color: var(--primary);
  background-color:var(--secondary) ;
}
}

@mixin devices ($breakpoint) {
  @if $breakpoint == Laptop {
    @media only screen and (max-width: 1440px) {
      @content;
    }
  }
  
  @if $breakpoint == Minlaptop {
    @media only screen and (max-width: 1125px) {
      @content;
    }
  }
  
    @if $breakpoint == tablet {    
    @media only screen and (max-width: 875px) {
      @content;
    }
  }
 
  @if $breakpoint == mobile {
    @media only screen and (max-width: 700px) {
      @content;
    }}
    @if $breakpoint == S_mobile {
      @media only screen and (max-width: 590px) {
        @content;
      }
    
  }
  @if $breakpoint == sh7ata {
    @media only screen and (max-width: 390px) {
      @content;
    }
  
}
}