

body{  min-width: 100vw; ; width: 100% ; 
  background: #2c2e31;
    overflow-x: hidden !important }
a{color: inherit; font-weight: bold; text-decoration: none !important; &:hover{color: var(--secondary);} }
*{
    font-family: 'Roboto' !important;
    box-sizing: border-box;
    margin: 0;

}
.Tool .ToolTip{
    background: var(--primary);
}   

.error{
    align-self: flex-start !important;
    color: red !important;
    
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
//   margin: 0;
}


::-webkit-scrollbar {
  min-width: 15px;
  width: 100%;
  min-height: 10px;
  display: none;
}
::-webkit-scrollbar-track {
  
  box-shadow: inset 0 0 5px var(--secondary);
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  border-radius: 2px;
  background-position: center;
  background-color: rgb(46, 45, 45);
  background-repeat: no-repeat;
  display: none;
}

.image-svg{
  background-position: center;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.color-primary{
  color: var(--primary);
}
.color-secondary{
  color: var(--secondary);
}
* {
  scroll-behavior: smooth !important;
}
button{
  cursor: pointer !important;
}

.remove-all-style{
  border: none !important;
  background: inherit !important;
  // all:unset !important;
  
}

.width-location{
  word-break: break-all;
}